.App {
  font-family: sans-serif;
  text-align: center;
  }
  
  /* img {
    height: 100px;
  } */
  
  .pagination a :hover{
    background:rgb(206, 203, 203)
  }
  .pagination {
    display: flex;
    justify-content: space-between;
    list-style: none;
    cursor: pointer;
    font-size: 15px;

  }
  
  .pagination a {
    padding: 10px;
    border-radius: 5px;
    /* color: #f87060; */
    margin-top: auto;
  }
  
  .pagination__link {
    font-weight: bold;
    font-size: 15px;
  }
  
  .pagination__link--active a {
    -webkit-text-fill-color: #fff;
    background-color:  #f87060;
    font-size: 15px;
    
  }
  
  .pagination__link--disabled a {
    color: rgb(198, 197, 202);
  }
  