//
// Card
//

.timeline.timeline-1 {
  position: relative;

  .timeline-sep {
    z-index: 0;
    content: "";
    position: absolute;
    left: 73px;
    width: 4px;
    top: 0;
    bottom: 0;
    background-color: #EBEDF3;
  }

  .timeline-item {
    flex-grow: 1;
    display: flex;
    margin-bottom: 1rem;

    & .timeline-label {
      display: flex;
      flex-wrap: wrap;
      flex: 0 0 60px;
      font-weight: 500;
      color: #B5B5C3;
    }

    & .timeline-badge {
      display: flex;
      width: 30px;
      height: 30px;
      flex-shrink: 0;
      align-items: center;
      justify-content: center;
      background-color: white;
      border-radius: 50%;
      border: 4px solid white;
      position: relative;
      z-index: 0;

      & .svg-icon,
      & i {
        line-height: 0;
      }
    }

    & .timeline-content {
      flex-grow: 1;
      font-size: 1rem;
      padding: 0 0 0 0.75rem;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.timeline.timeline-2 {
  position: relative;
  padding: 0;
  margin: 0;

  .timeline-bar {
    background-color: #EBEDF3;
    position: absolute;
    display: block;
    content: "";
    width: 1px;
    top: 5px;
    bottom: 5px;
    left: 4px;
    z-index: 0;
    margin: 0.5rem 0;
  }

  .timeline-item {
    display: flex;
    align-items: center;
    padding: 0.5rem 0;

    & .timeline-badge {
      position: relative;
      z-index: 1;
      display: block;
      width: 9px;
      height: 9px;
      border-radius: 100%;
      background-color: #E4E6EF;
      flex-shrink: 0;
      margin-right: 1.5rem;
    }

    & .timeline-badge.timeline-badge-primary {
      background-color: #3699FF;
    }

    & .timeline-badge.timeline-badge-secondary {
      background-color: #E4E6EF;
    }

    & .timeline-badge.timeline-badge-success {
      background-color: #1BC5BD;
    }

    & .timeline-badge.timeline-badge-info {
      background-color: #8950FC;
    }

    & .timeline-badge.timeline-badge-warning {
      background-color: #FFA800;
    }

    & .timeline-badge.timeline-badge-danger {
      background-color: #F64E60;
    }

    & .timeline-badge.timeline-badge-light {
      background-color: #F3F6F9;
    }

    & .timeline-badge.timeline-badge-dark {
      background-color: #181C32;
    }

    & .timeline-badge.timeline-badge-white {
      background-color: #ffffff;
    }

    & .timeline-content {
      flex-grow: 1;
    }
  }
}

.timeline.timeline-3 {
  .timeline-items {
    margin: 0;
    padding: 0;
  }

  .timeline-items .timeline-item {
    margin-left: 25px;
    border-left: 2px solid #EBEDF3;
    padding: 0 0 20px 50px;
    position: relative;

    & .timeline-media {
      position: absolute;
      top: 0;
      left: -26px;
      border: 2px solid #EBEDF3;
      border-radius: 100%;
      width: 50px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #ffffff;
      line-height: 0;

      & i {
        font-size: 1.4rem;
      }

      & .svg-icon svg {
        height: 24px;
        width: 24px;
      }

      & img {
        max-width: 48px;
        max-height: 48px;
        border-radius: 100%;
      }
    }

    & .timeline-content {
      border-radius: 0.85rem;
      position: relative;
      background-color: #F3F6F9;
      padding: 0.75rem 1.5rem;

      &:before {
        position: absolute;
        content: "";
        width: 0;
        height: 0;
        top: 10px;
        left: -25px;
        border-right: solid 10px #F3F6F9;
        border-bottom: solid 17px transparent;
        border-left: solid 17px transparent;
        border-top: solid 17px transparent;
      }
    }

    &:last-child {
      border-left-color: transparent;
      padding-bottom: 0;
    }
  }
}

.timeline.timeline-4 {
  position: relative;
  width: 100%;

  &:after {
    content: "";
    position: absolute;
    width: 5px;
    top: 0;
    margin-top: 0.1rem;
    bottom: 0;
    left: 50%;
    margin-left: -2.5px;
    background-color: #EBEDF3;
    border-radius: 0.42rem;
  }

  &.timeline-center {
    margin: 0 auto;
  }

  .timeline-bar {
    border-radius: 0.42rem;
    width: 20px;
    height: 5px;
    position: absolute;
    left: 50%;
    margin-left: -10px;
    background-color: #EBEDF3;
  }

  .timeline-items {
    position: relative;

    .timeline-item {
      position: relative;
      margin-left: 0;
      width: 50%;
      min-height: 3rem;

      &:after {
        position: absolute;
        content: "";
        width: 0;
        height: 0;
        top: 3rem;
        left: 100%;
        margin-left: -3rem;
        border-left: solid 10px #F3F6F9;
        border-bottom: solid 17px transparent;
        border-right: solid 17px transparent;
        border-top: solid 17px transparent;
      }

      & .timeline-badge {
        background: white;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1;
        position: absolute;
        top: 3.5rem;

        & > div {
          border-radius: 50%;
          width: 6px;
          height: 6px;
        }
      }

      & .timeline-label {
        display: block;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        margin-bottom: 0.5rem;
      }

      & .timeline-content {
        position: relative;
        border-radius: 0.42rem;
        padding: 1rem 1.5rem;
        background-color: #F3F6F9;
      }

      &.timeline-item-left {
        left: 0;
        padding-right: 2.85rem;
        position: relative;

        & .timeline-badge {
          right: -10px;
        }

        & .timeline-label {
          text-align: right;
        }
      }

      &.timeline-item-right {
        left: 50%;
        padding-left: 2.86rem;

        & .timeline-badge {
          left: -10px;
        }

        & .timeline-label {
          text-align: left;
        }

        &.timeline-item-right:after {
          right: 100%;
          left: auto;
          margin-right: -3rem;
          border-right: solid 10px #F3F6F9;
          border-left: solid 17px transparent;
        }
      }

      &:first-child {
        top: 2rem;
      }

      &:last-child {
        bottom: 2rem;
      }
    }
  }

  &.timeline-justified {
    .timeline-bar {
      left: 5px;
    }

    &:after {
      left: 5px;
    }

    .timeline-items {
      padding: 1rem 0;

      .timeline-item {
        width: 100%;
        left: 0;
        padding-left: 2.85rem;
        position: relative;
        top: 0;
        bottom: 0;
        margin-bottom: 1.5rem;

        &:after {
          right: 100%;
          left: auto;
          margin-left: auto;
          margin-right: -3rem;
          border-right: solid 10px #F3F6F9;
          border-left: solid 17px transparent;
        }

        .timeline-badge {
          left: -5px;
        }

        .timeline-label {
          text-align: left;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

@media (max-width: 991.98px) {
  .timeline.timeline-4 {
    .timeline-bar {
      left: 5px !important;
    }

    &:after {
      left: 5px !important;
    }

    .timeline-items {
      padding: 1rem 0;

      .timeline-item {
        width: 100% !important;
        left: 0 !important;
        padding-right: 0 !important;
        padding-left: 2.85rem !important;
        position: relative;
        top: 0 !important;
        bottom: 0 !important;
        margin-bottom: 1.5rem;

        &:after {
          right: 100%;
          left: auto !important;
          margin-left: auto;
          margin-right: -3rem;
          border-right: solid 10px #F3F6F9;
          border-left: solid 17px transparent;
        }

        .timeline-badge {
          left: -5px !important;
        }

        .timeline-label {
          text-align: left !important;
        }

        &:last-child {
          margin-bottom: 0 !important;
        }
      }
    }
  }
}

.timeline.timeline-5 {
  .timeline-items {
    margin: 0;
    padding: 0;
  }

  .timeline-items .timeline-item {
    margin-left: 0;
    padding: 0;
    position: relative;

    .timeline-media {
      position: absolute;
      top: 0;
      border-radius: 100%;
      width: 35px;
      height: 35px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #ffffff;
      line-height: 0;

      i {
        font-size: 1.4rem;
      }

      .svg-icon svg {
        height: 24px;
        width: 24px;
      }
    }

    .timeline-desc {
      padding: 9px 0 0 44px;

      &::before {
        content: "";
        position: absolute;
        width: 4px;
        height: calc(100% - 32px);
        border-radius: 6px;
        top: 40px;
        left: 16px;
      }

      &.timeline-desc-primary::before {
        background-color: #3699FF;
      }

      &.timeline-desc-light-primary::before {
        background-color: #E1F0FF;
      }

      &.timeline-desc-secondary::before {
        background-color: #E4E6EF;
      }

      &.timeline-desc-light-secondary::before {
        background-color: #EBEDF3;
      }

      &.timeline-desc-success::before {
        background-color: #1BC5BD;
      }

      &.timeline-desc-light-success::before {
        background-color: #C9F7F5;
      }

      &.timeline-desc-info::before {
        background-color: #8950FC;
      }

      &.timeline-desc-light-info::before {
        background-color: #EEE5FF;
      }

      &.timeline-desc-warning::before {
        background-color: #FFA800;
      }

      &.timeline-desc-light-warning::before {
        background-color: #FFF4DE;
      }

      &.timeline-desc-danger::before {
        background-color: #F64E60;
      }

      &.timeline-desc-light-danger::before {
        background-color: #FFE2E5;
      }

      &.timeline-desc-light::before {
        background-color: #F3F6F9;
      }

      &.timeline-desc-light-light::before {
        background-color: #F3F6F9;
      }

      &.timeline-desc-dark::before {
        background-color: #181C32;
      }

      &.timeline-desc-light-dark::before {
        background-color: #D1D3E0;
      }

      &.timeline-desc-white::before {
        background-color: #ffffff;
      }

      &.timeline-desc-light-white::before {
        background-color: #ffffff;
      }
    }

    &:last-child {
      border-left-color: transparent;
      padding-bottom: 0;
    }
  }
}

.timeline.timeline-6 {
  position: relative;

  &:before {
    content: "";
    position: absolute;
    left: 87px;
    width: 3px;
    top: 0;
    bottom: 0;
    background-color: #EBEDF3;
  }

  .timeline-item {
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 1.7rem;

    &:last-child {
      margin-bottom: 0;
    }

    .timeline-label {
      width: 50px;
      flex: 0 0 60px;
      font-weight: 500;
      position: relative;
      color: #3F4254;
    }

    .timeline-badge {
      background: white;
      width: 13px;
      height: 13px;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1;
      position: relative;
      margin-top: 1px;
      margin-left: -0.5rem;
      padding: 3px !important;
      border: 6px solid #ffffff !important;

      span {
        display: block;
        border-radius: 100%;
        width: 6px;
        height: 6px;
        background-color: #EBEDF3;
      }
    }

    .timeline-content {
      flex: 1;
    }
  }
}

.font-size-h4{
  font-size: 16px;
}
.top-40 {
  top: 40%;
}
#kt_chat_contacts_body .scroll-y {
  max-height: 407px !important;
}
.login-signin .ps__rail-y {
  height: 0 !important;
}
.image-container {
  position: relative;
}
.rti--tag button {
margin-left: 5px;
color: #F87060;
font-weight: 600;
}
.delete-button-container {
  position: absolute;
  top: 0;
  right: 0;
  visibility: hidden;
}

/* CSS for making the delete button visible on hover */
.image-container:hover .delete-button-container {
  visibility: visible;
}
.filter-button {
  transition: transform 0.3s ease-in-out;
}

.filter-button.clicked {
  transform: translateX(-14px);
}
.filter-container {
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}

.filter-container.hidden {
  opacity: 0;
  pointer-events: none; /* Disable interactions when hidden */
}
.rti--container:focus-within {
  border-color: var(--rti-main);
  box-shadow: var(--rti-main) 0 0 0 0px !important;
}
.rti--container {
  --rti-bg: #fff;
  --rti-border: #ccc;
  --rti-main: #3182ce;
  --rti-radius: .375rem;
  --rti-s: 0 !important;
  --rti-tag: #edf2f7;
  --rti-tag-remove: #e53e3e;
  --rti-tag-padding: 0 !important;
  align-items: center;
  background: var(--rti-bg);
  border: 0px solid var(--rti-border) !important;
  border-radius: var(--rti-radius);
  display: flex;
  flex-wrap: wrap;
  gap: var(--rti-s);
  line-height: 1.4;
  padding: var(--rti-s);
}
.menu-state-title-primary .menu-item .menu-link.active .menu-title.backoffice-title {
  color: var(--bs-primary);
}
.menu-state-title-primary .menu-item .menu-link.active .menu-title {
  color: var(--bs-primary) !important;
}
.menu-title-gray-700 .menu-item .menu-link .menu-title.backoffice-title {
color: #fff;
}
body:not([data-kt-sticky-header=on]) .topbar .btn.btn-custom.backoffice-title i, body:not([data-kt-sticky-header=on]) .topbar .btn.btn-custom.backoffice-title .svg-icon {
  color: #fff;
}
.backoffice-title .ki-duotone, .backoffice-title .ki-outline, .backoffice-title .ki-solid {
  color: #fff;
}
@media (min-width: 992px)

{
  body:not([data-kt-sticky-header=on]) .header .header-menu .menu > .menu-item > .menu-link.active .menu-title.backoffice-title {
    color: $primary;
  }
body:not([data-kt-sticky-header=on]) .header .header-menu .menu > .menu-item > .menu-link .menu-title.backoffice-title {
    color: #fff;
}
}
.react-tel-input .form-control {
  width: 100% !important;
  padding: 0.775rem 1rem 0.775rem 60px !important;
}
// Base
.card {
  @if ($card-border-enabled) {
    border: $card-border-width $card-border-style var(--#{$prefix}card-border-color);
  } @else {
    border: 0;
  }

  // Header
  .card-header {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-wrap: wrap;
    min-height: $card-header-height;
    padding: 0 $card-px;
    color: var(--#{$prefix}card-cap-color);
    background-color: var(--#{$prefix}card-cap-bg);
    border-bottom: $card-border-width $card-border-style var(--#{$prefix}card-border-color);

    // Title
    .card-title {
      display: flex;
      align-items: center;
      margin: $card-header-py;
      margin-left: 0;

      &.flex-column {
        align-items: flex-start;
        justify-content: center;
      }

      .card-icon {
        margin-right: 0.75rem;
        line-height: 0;

        i {
          font-size: 1.25rem;
          color: var(--#{$prefix}gray-600);
          line-height: 0;

          &:after,
          &:before {
            line-height: 0;
          }
        }

        .svg-icon {
          color: var(--#{$prefix}gray-600);
          @include svg-icon-size(24px);
        }
      }

      &,
      .card-label {
        font-weight: 500;
        font-size: 1.275rem;
        color: var(--#{$prefix}text-dark);
      }

      .card-label {
        margin: 0 0.75rem 0 0;
        flex-wrap: wrap;
      }

      // Description
      small {
        color: var(--#{$prefix}text-muted);
        font-size: 1rem;
      }

      // Headings
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        margin-bottom: 0;
      }
    }

    // Toolbar
    .card-toolbar {
      display: flex;
      align-items: center;
      margin: $card-header-py 0;
      flex-wrap: wrap;
    }
  }

  // Body
  .card-body {
    padding: $card-py $card-px;
    color: var(--#{$prefix}card-color);
  }

  // Footer
  .card-footer {
    padding: $card-py $card-px;
    color: var(--#{$prefix}card-cap-color);
    background-color: var(--#{$prefix}card-cap-bg);
    border-top: $card-border-width $card-border-style var(--#{$prefix}card-border-color);
  }

  // Scroll
  .card-scroll {
    position: relative;
    overflow: auto;
  }

  // Reset padding x
  &.card-px-0 {
    .card-header,
    .card-body,
    .card-footer {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &.card-py-0 {
    .card-header,
    .card-body,
    .card-footer {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  &.card-p-0 {
    .card-header,
    .card-body,
    .card-footer {
      padding: 0;
    }
  }

  // Dashed style
  &.card-dashed {
    box-shadow: none;
    border: $card-border-width dashed $card-border-dashed-color;

    > .card-header {
      border-bottom: 1px dashed $card-border-dashed-color;
    }

    > .card-footer {
      border-top: 1px dashed $card-border-dashed-color;
    }
  }

  // Bordered style
  &.card-bordered {
    box-shadow: none;
    border: $card-border-width $card-border-style $card-border-color;
  }

  // Flush header and footer borders
  &.card-flush {
    > .card-header {
      border-bottom: 0 !important;
    }

    > .card-footer {
      border-top: 0 !important;
    }
  }

  // Enable shadow
  &.card-shadow {
    box-shadow: var(--#{$prefix}card-box-shadow);
    border: 0;
  }

  // Reset styles
  &.card-reset {
    border: 0 !important;
    box-shadow: none !important;
    background-color: transparent !important;

    > .card-header {
      border-bottom: 0 !important;
    }

    > .card-footer {
      border-top: 0 !important;
    }
  }

  // Reset borders
  &.card-borderless {
    border: 0 !important;

    > .card-header {
      border-bottom: 0 !important;
    }

    > .card-footer {
      border-top: 0 !important;
    }
  }

  // Reset borders
  &.card-border-0 {
    border: 0 !important;
  }
}

// Responsive stretch heights
.card {
  @each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
      $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

      // Stretch
      &.card#{$infix}-stretch {
        height: calc(100% - var(--#{$prefix}gutter-y));
      }

      // Stretch 75
      &.card#{$infix}-stretch-75 {
        height: calc(75% - var(--#{$prefix}gutter-y));
      }

      // Stretch 50
      &.card#{$infix}-stretch-50 {
        height: calc(50% - var(--#{$prefix}gutter-y));
      }

      // Stretch 33
      &.card#{$infix}-stretch-33 {
        height: calc(33.333% - var(--#{$prefix}gutter-y));
      }

      // Stretch 25
      &.card#{$infix}-stretch-25 {
        height: calc(25% - var(--#{$prefix}gutter-y));
      }

      // Header stretch
      .card-header#{$infix}-stretch {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        align-items: stretch;

        .card-toolbar {
          margin: 0;
          align-items: stretch;
        }
      }
    }
  }
}

// Utilities
.card-p {
  padding: $card-py $card-px !important;
}

.card-px {
  padding-left: $card-px !important;
  padding-right: $card-px !important;
}

.card-shadow {
  box-shadow: var(--#{$prefix}card-box-shadow);
}

.card-py {
  padding-top: $card-py !important;
  padding-bottom: $card-py !important;
}

.card-rounded {
  border-radius: $card-border-radius;
}

.card-rounded-start {
  border-top-left-radius: $card-border-radius;
  border-bottom-left-radius: $card-border-radius;
}

.card-rounded-end {
  border-top-right-radius: $card-border-radius;
  border-bottom-right-radius: $card-border-radius;
}

.card-rounded-top {
  border-top-left-radius: $card-border-radius;
  border-top-right-radius: $card-border-radius;
}

.card-rounded-bottom {
  border-bottom-left-radius: $card-border-radius;
  border-bottom-right-radius: $card-border-radius;
}

// Mobile mode
@include media-breakpoint-down(md) {
  .card {
    > .card-header:not(.flex-nowrap) {
      padding-top: $card-header-py;
      padding-bottom: $card-header-py;
    }
  }
}

@if $enable-dark-mode {
  @include color-mode(dark) {
    .card {
      --#{$prefix}card-box-shadow: #{$card-box-shadow-dark};
    }
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}